<template>
  <div>
    <!-- <div style="width: 250px; padding: 20px">
      <el-input :value="phone" placeholder="请输入手机">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div> -->
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline"
      style="padding: 20px 20px 0"
    >
      <el-form-item label="提现人手机">
        <el-autocomplete
          v-model="queryForm.phone"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入手机号"
          :trigger-on-focus="false"
          value-key="phone"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="提现人ucid">
        <el-input v-model="queryForm.customer_ucid" placeholder="请输入" @input="handleInputUcid" />
      </el-form-item>
      <el-form-item label="提现渠道">
        <el-select v-model="queryForm.channel" placeholder="请选择">
          <el-option label="支付宝" :value="2" />
          <el-option label="银盛" :value="15" />
          <!-- <el-option label="微信" value="wechat" /> -->
        </el-select>
      </el-form-item>
      <el-form-item label="提现最低价">
        <el-input-number
          :controls="false"
          :min="0"
          class="number-input"
          v-model="queryForm.amount_start"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item label="提现最高价">
        <el-input-number
          :controls="false"
          :min="0"
          class="number-input"
          v-model="queryForm.amount_end"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item label="提现开始时间">
        <el-date-picker
          type="date"
          placeholder="请选择"
          v-model="queryForm.time_start"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
        />
        <!-- <el-input v-model="queryForm.time_start" placeholder="请输入" /> -->
      </el-form-item>
      <el-form-item label="提现结束时间">
        <el-date-picker
          type="date"
          placeholder="请选择"
          v-model="queryForm.time_end"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
        />

        <!-- <el-input v-model="queryForm.time_end" placeholder="请输入" /> -->
      </el-form-item>

      <el-form-item label="订单状态">
        <el-select v-model="queryForm.state" placeholder="请选择">
          <el-option label="全部" value="all" />
          <el-option label="待处理" :value="0" />
          <el-option label="进行中" :value="1" />
          <el-option label="已完成" :value="2" />
          <el-option label="提现失败" :value="-1" />
          <el-option label="已退回" :value="-2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <div style="padding-bottom: 20px;">
        <el-button
          :disabled="selectOrders.length === 0"
          type="success"
          size="default"
          @click="handleListRetry"
        >
          批量重试
        </el-button>
        <el-button
          :disabled="selectOrders.length === 0"
          type="danger"
          size="default"
          @click="handleListReturn"
        >
          批量退回
        </el-button>
      </div>
      <el-table
        :data="data"
        border
        stripe
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :selectable="selectable" width="40" />
        <el-table-column
          v-for="col in columns"
          :prop="col.id"
          :key="col.id"
          :label="col.label"
          :width="col.width"
          :sortable="col.sortable"
          :formatter="col.formatter"
        />
        <el-table-column prop="note" label="订单状态" :width="100">
          <template slot-scope="scope">
            <el-tag v-if="+scope.row.state === 0" size="small" type="info">
              待处理
            </el-tag>
            <el-tag v-if="+scope.row.state === 1" size="small">进行中</el-tag>
            <el-tag v-if="+scope.row.state === 2" size="small" type="success">
              已完成
            </el-tag>
            <el-tag
              v-if="+scope.row.state === -1 && +scope.row.channel === 15"
              size="small"
              type="danger"
            >
              失败已退回
            </el-tag>
            <el-popover
              title="失败原因"
              placement="top"
              trigger="hover"
              :width="200"
              v-if="+scope.row.state === -1 && +scope.row.channel !== 15"
            >
              <div>
                {{ scope.row.note || "暂无" }}
              </div>
              <el-tag slot="reference" size="small" type="danger">
                提现失败
              </el-tag>
            </el-popover>

            <el-tag v-if="+scope.row.state === -2" size="small" type="warning">
              已退回
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleGoUser(scope.row)">
              用户统计
            </el-button>
            <el-button
              v-if="+scope.row.state === -1 && +scope.row.channel !== 15"
              size="mini"
              type="success"
              @click="handleRetry([scope.row])"
              style="margin-top: 8px"
            >
              重试
            </el-button>
            <el-button
              v-if="+scope.row.state === -1 && +scope.row.channel !== 15"
              size="mini"
              type="danger"
              @click="handleReturn([scope.row])"
              style="margin-top: 8px"
            >
              退回
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getWithdrawList,
  retryWithdrawOrders,
  refundWithdrawOrders,
  searchUserByPhone,
} from "../../../../api/nft";
import dayjs from "dayjs";
import { StartTime, EndTime } from "@/utils/helper.js";

const mapOrderToId = (order) => order.order_id;
const tableSortOrder2SortType = (val) => {
  if (val === "descending") {
    return "desc";
  }
  if (val === "ascending") {
    return "asc";
  }
  return "";
};

export default {
  data() {
    return {
      queryForm: {},
      page: 1,
      pageSize: 20,
      sortParams: {
        sort_field: "timestamp",
        sort_type: "desc",
      },
      total: 0,
      selectOrders: [],
      data: [],
      columns: [
        {
          id: "order_id",
          label: "订单编号",
          width: 120,
        },
        {
          id: "timestamp",
          label: "提现时间",
          sortable: "custom",
          width: 110,
          formatter: (row) => dayjs(+row.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          id: "ucid",
          label: "ucid",
          width: 180,
        },
        {
          id: "nickname",
          label: "昵称",
          width: 120,
        },
        {
          id: "is_certify",
          label: "是否认证",
          width: 80,
          formatter: (row) => {
            return row.is_certify ? (
              <el-tag size="small" type="success">
                已认证
              </el-tag>
            ) : (
              <el-tag size="small">未认证</el-tag>
            );
          },
        },
        {
          id: "channel",
          label: "提现渠道",
          width: 80,
          formatter: (row) => {
            if (+row.channel === 15) {
              return "银盛";
            }
            return ["", "微信", "支付宝"][+row.channel] || "";
          },
        },
        {
          id: "account",
          label: "提现账号",
          width: 120,
        },
        {
          id: "realname",
          label: "姓名",
        },
        {
          id: "amount",
          label: "提现金额",
          sortable: "custom",
          width: 110,
        },
        {
          id: "fee",
          label: "手续费",
        },

        {
          id: "channel_order_id",
          label: "渠道订单号",
        },
        {
          id: "channel_order_time",
          label: "渠道订单时间",
          formatter: (row) => {
            return dayjs(+row.channel_order_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
    };
  },
  computed: {
    queryParams() {
      let time_end = undefined;
      let time_start = undefined;
      if (this.queryForm.time_end) {
        time_end = EndTime(this.queryForm.time_end);
      }
      if (this.queryForm.time_start) {
        time_start = StartTime(this.queryForm.time_start);
      }
      if (this.queryForm.state === "all") {
        delete this.queryForm.state;
      }

      return {
        ...this.queryForm,
        time_end,
        time_start,
        page: this.page,
        page_size: this.pageSize,
        ...this.sortParams,
      };
    },
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);
    const id = search.get("ucid");
    if (id) {
      this.queryForm = {
        ...this.queryForm,
        customer_ucid: id,
      };
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const amount_start = this.queryParams.amount_start;
      const amount_end = this.queryParams.amount_end;

      if (amount_start > amount_end) {
        this.$message.error("提现最高价需要大于提现最低价");
        return;
      }

      const time_end = this.queryParams.time_end;
      const time_start = this.queryParams.time_start;

      if (time_start > time_end) {
        this.$message.error("开始时间不能大于结束时间");
        return;
      }

      const params = { ...this.queryParams };
      delete params.phone;

      try {
        const { data } = await getWithdrawList(params);
        const { total = 0, items = [] } = data?.data;
        this.data = [...items];
        this.total = total;
      } catch (error) {
        console.log(error);
      }
    },

    async getUser(phone) {
      if (phone.length !== 11) {
        return [];
      }

      try {
        const { data } = await searchUserByPhone(phone);
        return data.data.map((i) => ({
          phone: i.mobile,
          value: i.id,
        }));
      } catch (error) {
        return [];
      }
    },

    async querySearchAsync(queryString, cb) {
      const res = await this.getUser(queryString);
      cb(res);
    },

    handleSelect(item) {
      if (item.value) {
        this.queryForm = {
          ...this.queryForm,
          customer_ucid: item.value,
        };
      }
    },
    handleInputUcid(val) {
      this.queryForm = {
        ...this.queryForm,
        phone: "",
      };
      return val;
    },

    handleListRetry() {
      this.handleRetry([...this.selectOrders]);
    },
    handleListReturn() {
      this.handleReturn([...this.selectOrders]);
    },
    // 重试
    async handleRetry(orders) {
      if (!Array.isArray(orders)) {
        this.$message("数据异常，请刷新页面重试");
        return;
      }
      const ids = orders.map(mapOrderToId);
      if (ids.length <= 0) {
        return;
      }
      try {
        const { data } = await retryWithdrawOrders({
          order_ids: ids,
        });
        if (data?.msg) {
          this.$message(data?.msg);
        }
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
    // 退回
    async handleReturn(orders) {
      if (!Array.isArray(orders)) {
        this.$message("数据异常，请刷新页面重试");
        return;
      }
      const ids = orders.map(mapOrderToId);
      if (ids.length <= 0) {
        return;
      }
      try {
        const { data } = await refundWithdrawOrders({
          order_ids: ids,
        });
        if (data?.msg) {
          this.$message(data?.msg);
        }
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
    handleGoUser(order) {
      this.$router.push("/nft_management_log/asset?id=" + order.ucid);
    },
    handleSortChange(e) {
      const { prop, order } = e;
      // 默认时间降序
      if (order === null) {
        this.sortParams = {
          sort_field: "timestamp",
          sort_type: "desc",
        };
      } else {
        this.sortParams = {
          sort_field: prop,
          sort_type: tableSortOrder2SortType(order),
        };
      }
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.selectOrders = val;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
    onClear() {
      this.page = 1;
      this.pageSize = 20;
      this.queryForm = {};
      this.fetchData();
    },
    selectable(row) {
      return +row.state === -1 && +row.channel != 15;
    },
  },
};
</script>

<style lang="less">
.number-input.el-input-number .el-input__inner {
  text-align: left;
}
</style>
